@import "~perfect-scrollbar/css/perfect-scrollbar.css";

/* @media print {
  .ps {
    overflow: scroll !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
  }
} */

.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.cut-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.cut-text.show-on-hover:hover {
  text-overflow: none;
  white-space: inherit;
  overflow:inherit;
  width: auto !important;
}
.strong{
  font-weight: bold;
}
.MuiTableCell-root {
  word-break: unset !important;
}
.no-list-style li{
  list-style: none;
}
.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer !important;
}

.pointer:hover {
  alpha: 0.7 !important;
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
}

.no-margin {
  margin: 0;
}

.red, .text-danger {
  color: red !important;
}

.green {
  color: green !important;
}

.yellow {
  background-color: rgb(255, 234, 0);
}

.orange {
  background-color: rgb(255, 153, 0);
}
.bg-danger, .bg-red {
  background-color: rgb(241, 71, 71) !important;
  color: white;
}
.bg-danger-light, .bg-red-light, .bg-error-light {
  background-color: #e3bcbc !important;
  color: black;
}
.bg-success, .bg-green {
  background-color: rgb(0, 124, 2) !important;
  color: white;
}
.bg-warning, .bg-orange {
  background-color: rgb(184, 123, 0) !important;
  color: white;
}
.text-warning{
  color: rgb(255, 153, 0);
}
.p-inherit{
  position: inherit !important;
}
.bg-light{
  background: rgb(255, 251, 247) !important;
}
.bg-light-warning{
  background: rgb(253, 245, 234) !important;
}
.no-decoration {
  text-decoration: none !important;
}
.text-light-success {
  color: #64c88f !important; }

.wrap{
  white-space: initial !important;
}
.capitalize{
  text-transform: capitalize;
}

@media only screen and (max-width : 959px) {
  .MuiTableRow-root td:nth-child(1) {
    background-color: white;
    position: absolute;
    padding: 0;
  }

  .MuiTableRow-root td:nth-child(2)>div {
    margin-left: 40px;
    width: calc(50% - 70px)
  }
}

.markdown-preview{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  width: 100%;
  white-space: pre-wrap; 
}
.chip{
  display: inline-block;
  border-radius: 5px;
  background: #b4b4b4;
  padding: 0px 5px;
}
.chip p{
  margin: 0 0 -10px 0;
  padding: 0;
}
.chip small{
  font-size: 8px;
}

.rbc-event{
  background: #1675d2;
}